import Layout from "layout";
import React from "react";
import styled from "styled-components";

const ShippingContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-weight: bold;
  list-style-type: disc;
`;

const ShippingPolicy = () => {
  return (
    <Layout>
      <ShippingContainer>
        <Title>Shipping Policy</Title>
        <Content>
          We offer fast and reliable shipping solutions tailored to eCommerce
          businesses.
        </Content>
        <List>
          <ListItem>Processing Time</ListItem>
          <Content>
            Orders are processed within 24-48 hours after confirmation.
          </Content>
        </List>
        <List>
          <ListItem>Shipping Methods</ListItem>
          <Content>Standard and expedited shipping options available.</Content>
        </List>
        <List>
          <ListItem>Delivery Time</ListItem>
          <Content>
            <strong>USA:</strong> 5-10 business days (from our US warehouse).
          </Content>
          <Content>
            <strong>International:</strong> 7-20 business days (from China
            warehouse).
          </Content>
        </List>
        <List>
          <ListItem>Tracking</ListItem>
          <Content>Provided for all orders.</Content>
        </List>
        <List>
          <ListItem>Customs & Duties</ListItem>
          <Content>
            Customers are responsible for any applicable duties or taxes.
          </Content>
        </List>
      </ShippingContainer>
    </Layout>
  );
};

export default ShippingPolicy;
