import React from "react";
import {
  HeadingCostWrapper,
  MainContainer,
  ProductCost,
  ProductHeading,
  ProductInfoWrapper,
  StyledImage,
  InnerContainer,
  StyledQuantity,
  StyledNumberInput,
} from "./styles";
import CartProductImage from "@/assets/CartProductImage.png";
import CrossIcon from "@/assets/icons/crossIcon";

const ProductLineItems = ({ cartItem, onRemove, onQuantityChange }) => {
  const handleQuantityChange = (value) => {
    onQuantityChange(cartItem.productId, value);
  };

  return (
    <MainContainer>
      <InnerContainer>
        <ProductInfoWrapper>
          <StyledImage
            src={cartItem?.productImage}
            alt={cartItem?.productName || "Product Image"}
          />
          <HeadingCostWrapper>
            <ProductHeading>{cartItem?.productName}</ProductHeading>
            <ProductCost>{`Product Cost : $${
              cartItem?.variantPrice !== 0
                ? cartItem?.variantPrice
                : cartItem?.productPrice
            }`}</ProductCost>
          </HeadingCostWrapper>
          <StyledQuantity>
            <div>
              <StyledNumberInput
                min={1}
                size="medium"
                value={cartItem?.productQuantity}
                onChange={handleQuantityChange}
              />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => onRemove(cartItem?.productId)}
            >
              <CrossIcon />
            </div>
          </StyledQuantity>
        </ProductInfoWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default ProductLineItems;
