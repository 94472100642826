import React from "react";
import { Pagination } from "antd";
import ProductCard from "../ProductCard";
import {
  MainContainer,
  StyledHeading,
  InnerContainer,
  PaginationWrapper,
} from "./styles";

const onChange = (pageNumber) => {};

const ProductSection = ({
  heading,
  products,
  currentPage,
  total,
  onPageChange,
}) => {
  return (
    <MainContainer>
      <StyledHeading>{heading}</StyledHeading>
      <InnerContainer>
        {products?.map((product) => (
          <ProductCard product={product} />
        ))}
      </InnerContainer>
      <PaginationWrapper>
        <Pagination
          current={currentPage}
          total={total || 0}
          pageSize={10}
          onChange={onPageChange}
        />
      </PaginationWrapper>
    </MainContainer>
  );
};

export default ProductSection;
