import Layout from "layout";
import React from "react";
import styled from "styled-components";

const ReturnContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-weight: bold;
  list-style-type: disc;
`;

const ReturnPolicy = () => {
  return (
    <Layout>
      <ReturnContainer>
        <Title>Return Policy</Title>
        <Content>
          We aim for 100% customer satisfaction. If an issue arises, our return
          policy ensures a smooth resolution.
        </Content>
        <List>
          <ListItem>Eligibility for Returns</ListItem>
          <Content>
            <strong>Defective or damaged items.</strong>
          </Content>
          <Content>
            <strong>Incorrect product received.</strong>
          </Content>
          <Content>
            <strong>
              Returns must be requested within 7 days of delivery.
            </strong>
          </Content>
        </List>
        <List>
          <ListItem>Return Process</ListItem>
          <Content>
            <strong>Contact us</strong> with order details and issue
            description.
          </Content>
          <Content>
            <strong>If approved</strong>, return instructions will be provided.
          </Content>
          <Content>
            <strong>Refunds/store credit</strong> issued upon inspection.
          </Content>
        </List>
        <List>
          <ListItem>Non-Returnable Items</ListItem>
          <Content>
            <strong>Customized or perishable goods.</strong>
          </Content>
          <Content>
            <strong>Buyer’s remorse returns.</strong>
          </Content>
        </List>
      </ReturnContainer>
    </Layout>
  );
};

export default ReturnPolicy;
