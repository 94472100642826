import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import AnnouncementBar from "../MarketingPage/AnnouncementBar";
import MetaDataCard from "shared/MetaDataCard";
import Header from "../MarketingPage/Header";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";
import MetaFire from "@/assets/icons/metaFire";
import MetaPlay from "@/assets/icons/metaPlay";
import MetaRocket from "@/assets/icons/metaRocket";
import MetaStar from "@/assets/icons/metaStar";
import MetaComment from "@/assets/icons/metaComment";
import Lock from "@/assets/icons/lock";
import { useParams, useNavigate } from "react-router-dom";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  StyledBreadCrumbs,
  HeadingText,
  BodyContainer,
  StyledImageGallery,
  LeftContainer,
  ImageGalleryContainer,
  MetaDataWrapper,
  RightContainer,
  LabelText,
  ReviewsStockWrapper,
  InStockText,
  StyledDivider,
  ReviewsText,
  PriceContainer,
  PriceWrapper,
  PriceLabel,
  LineContainer,
  LeftHalf,
  RightHalf,
  CategoryText,
  PriceValue,
  ProductDescription,
  DescriptionDivider,
  StyledSelectContainer,
  StyledSelect,
  StyledLabel,
  StyledQuantityContainer,
  StyledInputNumber,
  StyledPlusButton,
  StyledMinusButton,
  StyledBuyButton,
  InputButtonContainer,
} from "./styles";
import { useGetProductDetailQuery } from "api/productApi";
import cache from "@/utils/cache";
import { STOCK_STATUS } from "@/utils/constants";
import Loader from "shared/Loader";
import { message } from "antd";
import SignInModal from "../MarketingPage/SignInModal";

const items = [
  {
    title: <a href="">Product</a>,
  },
  {
    title: <a href="">Best Selling Products</a>,
  },
  {
    title: <a href="">Product Detail</a>,
  },
];

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [prices, setPrices] = useState({ price: null, msrp: null });
  const { data: productDetails, isLoading } = useGetProductDetailQuery(id);

  useEffect(() => {
    if (productDetails?.product?.options) {
      const initialVariants = productDetails.product.options.reduce(
        (acc, option) => {
          acc[option.name] = option.values[0];
          return acc;
        },
        {}
      );

      setSelectedVariants(initialVariants);

      
      if (
        productDetails?.product?.variants &&
        productDetails?.product?.variants?.length > 0
      ) {
        const initialVariant = productDetails.product.variants[0]; // Default to the first variant
        setPrices({ price: initialVariant.price, msrp: initialVariant.MSRP });
      } else {
        
        setPrices({
          price: productDetails.product.price,
          msrp: productDetails.product.MSRP,
        });
      }
    }
  }, [productDetails]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  const { Option } = StyledSelect;
  const { product } = productDetails || {};
  const {
    metaData: { totalSales, GMV, totalInfluencers, totalComments } = {},
  } = product || {};
  const user = cache.getItem("user");
  console.log("user", user);
  const cartItems = cache.getItem("cartItems");
  console.log("cartItems", cartItems);

  const originalHeight = 600;
  const mergedUrls = [
    ...(product?.images?.map((item) => item?.url) || []),
    ...(product?.variants?.map((item) => item?.image) || []),
  ];
  const images = mergedUrls.map((url) => ({
    original: url,
    thumbnail: url,
    originalHeight: originalHeight,
  }));

  const onDecrease = () => {
    setQuantity((prevQuantity) => Math.max(0, prevQuantity - 1));
  };

  const onIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleVariants = (key, value) => {
    console.log("Full productDetails:", productDetails);

    
    setSelectedVariants((prevVariants) => ({
      ...prevVariants,
      [key]: value,
    }));

    
    const selectedVariant = productDetails.product.variants.find(
      (variant) => variant.name === value
    );
    if (selectedVariant) {
      setPrices({ price: selectedVariant.price, msrp: selectedVariant.MSRP });
    } else {
      
      setPrices({
        price: productDetails.product.price,
        msrp: productDetails.product.MSRP,
      });
    }
  };

  const toggleModal = () => {
    setShowSignUpModal(!showSignUpModal);
  };

  const handleAddToCart = () => {
    const variant = Object.values(selectedVariants).join(" / ");
    const finalVariant = product?.variants?.find(
      (item, index) => item.name === variant
    );
    const finalPayload = {
      productId: product?.id,
      productName: product?.name,
      productPrice: product?.price,
      productImage: product?.images[0]?.url,
      productQuantity: quantity,
      variantId: finalVariant?.id,
      variantPrice: finalVariant?.price,
    };
    if (cartItems !== null) {
      cache.setItem("cartItems", [...cartItems, finalPayload]);
    } else {
      cache.setItem("cartItems", [finalPayload]);
    }
    message.success("Product added to cart");
  };

  const renderItem = (item) => (
    <div className="image-gallery-image">
      <InnerImageZoom
        src={item.original}
        zoomSrc={item.original}
        zoomType="hover"
        zoomScale={2}
        alt=""
      />
    </div>
  );

  return (
    <>
      <AnnouncementBar />
      <Header />
      <StyledBreadCrumbs items={items} />
      <HeadingText>Product Detail</HeadingText>
      <BodyContainer>
        <LeftContainer>
          <ImageGalleryContainer>
            <StyledImageGallery
              items={images}
              thumbnailPosition="left"
              showPlayButton={false}
              showFullscreenButton={false}
              showNav={false}
              renderItem={renderItem}
              startIndex={0}
            />
          </ImageGalleryContainer>
          <MetaDataWrapper>
            <MetaDataCard
              value={totalSales}
              label="Total Sales"
              icon={<MetaFire />}
            />
            <MetaDataCard value={GMV} label="GMV" icon={<MetaRocket />} />
            <MetaDataCard
              value={totalInfluencers}
              label="Total Influencers"
              icon={<MetaFire />}
            />
            <MetaDataCard
              value="105"
              label="Total Videos"
              icon={<MetaPlay />}
            />
            <MetaDataCard
              value={totalComments}
              label="Total Comments"
              icon={<MetaComment />}
            />
          </MetaDataWrapper>
        </LeftContainer>
        <RightContainer>
          <LabelText>{product?.name}</LabelText>
          <ReviewsStockWrapper>
            <StarRatings
              rating={5}
              starRatedColor="#FFAD33"
              numberOfStars={5}
              starDimension="15px"
              starSpacing="2px"
              name="rating"
            />
            <ReviewsText>(150 Reviews)</ReviewsText>
            <StyledDivider type="vertical" />
            <InStockText>{STOCK_STATUS[product?.stock_status]}</InStockText>
          </ReviewsStockWrapper>
          <CategoryText>Dropship</CategoryText>
          <LineContainer>
            <LeftHalf />
            <RightHalf />
          </LineContainer>
          <PriceContainer>
            <PriceWrapper>
              <PriceLabel>Price</PriceLabel>
              {user ? <PriceValue>${prices.price}</PriceValue> : <Lock />}
            </PriceWrapper>
            {(!product?.variants || product.variants.length === 0) && (
              <PriceWrapper>
                <PriceLabel>MSRP</PriceLabel>
                <PriceValue>${prices.msrp}</PriceValue>
              </PriceWrapper>
            )}
          </PriceContainer>
          <ProductDescription>{product?.description}</ProductDescription>
          <DescriptionDivider></DescriptionDivider>
          {product?.options?.map((option) => (
            <StyledSelectContainer key={option?.id}>
              <StyledLabel>{option?.name}:</StyledLabel>
              <StyledSelect
                size="large"
                value={selectedVariants[option?.name] || option?.values[0]} // Controlled value
                onChange={(value) => handleVariants(option?.name, value)}
              >
                {option?.values?.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </StyledSelect>
            </StyledSelectContainer>
          ))}
          <StyledQuantityContainer>
            <StyledLabel>Quantity</StyledLabel>
            <InputButtonContainer>
              <StyledMinusButton onClick={onDecrease}>
                <MinusOutlined />
              </StyledMinusButton>
              <StyledInputNumber
                min={0}
                size="large"
                value={quantity}
                onChange={setQuantity}
              />
              <StyledPlusButton onClick={onIncrease}>
                <PlusOutlined />
              </StyledPlusButton>
            </InputButtonContainer>
          </StyledQuantityContainer>
          {user && user?.subscription?.status === "ACVTIVE" ? (
            <StyledBuyButton size="large" onClick={handleAddToCart}>
              Add To Cart
            </StyledBuyButton>
          ) : user ? (
            <StyledBuyButton size="large" onClick={() => navigate("/pricing")}>
              Subscribe To Buy Product
            </StyledBuyButton>
          ) : (
            <StyledBuyButton size="large" onClick={toggleModal}>
              Sign Up to Unlock Product Cost
            </StyledBuyButton>
          )}
        </RightContainer>
        {!!showSignUpModal && (
          <SignInModal
            isModalOpen={showSignUpModal}
            toggleModal={toggleModal}
            isSignUpMode={true}
          />
        )}
      </BodyContainer>
    </>
  );
};

export default ProductDetail;
