import React from "react";
import Layout from "layout";
import styled from "styled-components";

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  font-weight: bold;
`;

const About = () => {
  return (
    <Layout>
      <AboutContainer>
        <Title>About Ecom Dropship</Title>
        <Content>
          Ecom Dropship is your trusted B2B dropshipping agent, dedicated to
          helping eCommerce sellers in the USA streamline their order
          fulfillment. With over six years of industry experience and
          strategically located warehouses in China and the USA, we provide a
          seamless, end-to-end solution for sourcing, stocking, and shipping
          your products.
        </Content>
        <Content>
          Finding a reliable dropshipping partner can be challenging. Delays,
          quality issues, and lack of transparency often lead to frustration and
          lost revenue. At Ecom Dropship, we eliminate these pain points by
          offering a dependable and efficient service. From sourcing
          high-quality products at competitive prices to managing logistics and
          order tracking, we ensure your business runs smoothly, allowing you to
          focus on growth.
        </Content>
        <List>
          <ListItem>
            🚀 Fast & Reliable Fulfillment: Minimized delivery times with
            warehouses in China & USA.
          </ListItem>
          <ListItem>
            🔍 Quality Assurance: Thorough inspections and supplier
            verification.
          </ListItem>
          <ListItem>
            🔗 Seamless Integration: Hassle-free order processing tailored to
            your eCommerce needs.
          </ListItem>
          <ListItem>
            💰 Transparent Pricing: No hidden fees—just straightforward,
            cost-effective solutions.
          </ListItem>
        </List>
        <Content>
          Let us handle your supply chain so you can scale with confidence.
        </Content>
      </AboutContainer>
    </Layout>
  );
};

export default About;
