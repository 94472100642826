import Layout from "layout";
import React from "react";
import styled from "styled-components";

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  color: #444;
  margin-top: 20px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  list-style-type: disc;
`;

const TermsOfService = () => {
  return (
    <Layout>
      <TermsContainer>
        <Title>Terms of Service</Title>
        {/* <Subtitle>Last updated: [Insert Date]</Subtitle> */}
        <Content>
          Welcome to Ecom Dropship. By using our services, you agree to comply
          with the following terms and conditions. Please read them carefully
          before proceeding.
        </Content>
        <List>
          <ListItem>
            Service Scope – Ecom Dropship provides sourcing, fulfillment, and
            shipping solutions for eCommerce sellers. We act as an intermediary
            between suppliers and store owners, ensuring smooth order
            processing.
          </ListItem>
          <ListItem>
            User Responsibilities – You must provide accurate business details,
            ensure compliance with marketplace policies, and maintain a valid
            payment method.
          </ListItem>
          <ListItem>
            Order Processing – Orders are processed upon payment confirmation.
            Processing times vary based on product type and availability.
          </ListItem>
          <ListItem>
            Shipping & Delivery – We strive to meet estimated delivery times,
            but we are not responsible for delays caused by external factors
            such as customs clearance or courier issues.
          </ListItem>
          <ListItem>
            Refunds & Returns – See our Return Policy for guidelines on returns
            and disputes.
          </ListItem>
          <ListItem>
            Termination – We reserve the right to suspend or terminate service
            in case of fraudulent activity, non-payment, or policy violations.
          </ListItem>
        </List>
        <Content>
          For the full Terms of Service, contact our support team.
        </Content>
      </TermsContainer>
    </Layout>
  );
};

export default TermsOfService;
