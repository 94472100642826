import React, {useState} from "react";
import AnnouncementBar from "../MarketingPage/AnnouncementBar";
import Header from "../MarketingPage/Header";
import Footer from "../MarketingPage/Footer";
import ProductSection from "../BestSellingProductListPage/ProductSection";
import NewArrivalSection from "../BestSellingProductListPage/NewArrivalSection";
import IconWithTextSection from "../BestSellingProductListPage/IconWithTextSection";
import HeroBannerCarousel from "./HeroBannerCarousel";
import { useGetProductsQuery } from "api/productApi";
import Loader from "shared/Loader";

const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const { data: bestSellingData, isLoading } = useGetProductsQuery({
    page: currentPage,
    limit,
  });
  return (
    <>
      <AnnouncementBar />
      <Header />
      <HeroBannerCarousel />
      <ProductSection
        heading="Latest Products"
        products={bestSellingData?.products}
        currentPage={currentPage}
        total={bestSellingData?.total}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <NewArrivalSection />
      <IconWithTextSection />
      <Footer />
      <Loader isLoading={isLoading} />
    </>
  );
};

export default LandingPage;
