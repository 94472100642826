import Layout from "layout";
import React from "react";
import styled from "styled-components";

const PrivacyContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  color: #444;
  margin-top: 20px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-weight: bold;
  list-style-type: disc;
`;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <PrivacyContainer>
        <Title>Privacy Policy</Title>
        <Subtitle>Your privacy is important to us.</Subtitle>
        <Content>
          This policy outlines how Ecom Dropship collects, uses, and protects
          your information.
        </Content>
        <List>
          <ListItem>Information We Collect</ListItem>
          <Content>Business name, email, and contact details.</Content>
          <Content>Order history and transaction records.</Content>
          <Content>Website usage data for analytics.</Content>
        </List>
        <List>
          <ListItem>How We Use Your Information</ListItem>
          <Content>To fulfill orders and provide customer support.</Content>
          <Content>To improve our services and website functionality.</Content>
          <Content>To comply with legal and regulatory requirements.</Content>
        </List>
        <List>
          <ListItem>Data Protection</ListItem>
          <Content>
            We implement security measures to safeguard your data.
          </Content>
          <Content>
            We do not sell or share personal information with third parties,
            except as required for order fulfillment.
          </Content>
        </List>
        <Content>
          For questions regarding your data, contact our support team.
        </Content>
      </PrivacyContainer>
    </Layout>
  );
};

export default PrivacyPolicy;
