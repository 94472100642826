import React from "react";
import footerLogo from "@/assets/FooterLogo.png";
import {
  MainContainer,
  InnerContainer,
  FooterHeading,
  FooterBody,
  LogoWrapper,
  FooterLogo,
  MenuColumn,
  StyledLink,
  FooterTextWrapper,
  FooterText,
  FooterHeadingmb,
} from "./styles";

const Footer = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <FooterHeading>{`GROW YOUR BUSINESS WITH ECOM DROPSHIP`}</FooterHeading>
        <FooterBody>
          <LogoWrapper>
            <FooterLogo src={footerLogo}></FooterLogo>
          </LogoWrapper>
          <FooterHeadingmb>{`GROW YOUR BUSINESS WITH ECOM DROPSHIP`}</FooterHeadingmb>
          <MenuColumn>
            <StyledLink href="#">{`COMPANY`}</StyledLink>
            <StyledLink href="/about">{`About ECOM DROPSHIP`}</StyledLink>
            <StyledLink href="/terms_services">{`Terms of Service`}</StyledLink>
            <StyledLink href="/privacy_policy">{`Privacy Policy`}</StyledLink>
          </MenuColumn>
          <MenuColumn>
            <StyledLink href="#">{`HELP & SUPPORT`}</StyledLink>
            <StyledLink href="/shipping">{`Shipping Policy`}</StyledLink>
            <StyledLink href="/return_policy">{`Return Policy`}</StyledLink>
            <StyledLink href="/supplier_page">{`Become a supplier`}</StyledLink>
          </MenuColumn>
          <FooterTextWrapper>
            <FooterText>
              {
                "Your one-stop supplier for TikTok Shop US! ECOM-DROPSHIP supports thousands of people start and grow their eCommerce empire."
              }
            </FooterText>
          </FooterTextWrapper>
        </FooterBody>
      </InnerContainer>
    </MainContainer>
  );
};

export default Footer;
