import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async () => {
  console.log("handle401Error called!");
  // Rest of your middleware logic
};

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    credentials: "include",
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    addProduct: builder.mutation({
      query: (body) => ({
        url: "products/create",
        method: "POST",
        body,
      }),
    }),
    getProducts: builder.query({
      query: ({
        type = "",
        badge = "",
        page = 1,
        limit = 25,
        sortBy = "created",
        sortOrder = "DESC",
        searchFilter = "",
      }) => {
        let url = `products/public`;
        const params = [];
        params.push(`page=${page}`);
        params.push(`limit=${limit}`);
        params.push(`sortBy=${encodeURIComponent(sortBy)}`);
        params.push(`sortOrder=${encodeURIComponent(sortOrder)}`);
        if (searchFilter) params.push(`searchFilter=${encodeURIComponent(searchFilter)}`);
        if (type) params.push(`type=${encodeURIComponent(type)}`);
        if (badge) params.push(`badge=${encodeURIComponent(badge)}`);
        url += `?${params.join("&")}`;
        return url;
      },
    }),
    getProductDetail: builder.query({
      query: (id) => `products/public/${id}?type=BEST_SELLING`,
    }),
    getPresignedUrl: builder.query({
      query: (filename) => `products/generate-presigned-url?filename=${encodeURIComponent(filename)}`,
    }),
    getSupplierProducts: builder.query({
      query: () => `products`,
    }),
  }),
});

export const {
  useAddProductMutation,
  useGetProductsQuery,
  useGetPresignedUrlQuery,
  useGetProductDetailQuery,
  useGetSupplierProductsQuery,
} = productApi;
